import React from 'react';
import { getSanitizedImageUrl } from 'utils/commonHelpers';

const ReadAlongMobilePreview = ({ heading, image, completeSentence }) => {
	return (
		<div className="container">
			<div className="row">
				<div
					className="col-14
        text-center rounded-lg border border-secondary"
					style={{ height: 'auto', width: '74%', marginLeft: '5%' }}
				>
					<img className="" src={getSanitizedImageUrl('assets/admin/header.png')} alt="speakx icon" />
					{image && image.length > 0 && (
						<img
							src={image[0].preview || (window.URL || window.webkitURL).createObjectURL(image[0])}
							style={{ width: '201px', height: '105px' }}
							alt="Read Along"
							className="img-fluid rounded"
						/>
					)}
					<img className="pt-4" src={getSanitizedImageUrl('assets/admin/voice.png')} style={{ paddingTop: '10%' }} alt="voice icon" />
					<div className="d-flex flex-column justify-content-between">
						<h4 className="font-weight-normal text-dark pt-3 pb-2 pl-2 pr-2 font-weight-bold">{completeSentence}</h4>
						<h4 className="font-weight-normal text-dark pt-3 pb-2 pl-2 pr-2 font-weight-bold">{heading}</h4>
						<img
							src={getSanitizedImageUrl('assets/admin/speak.jpg')}
							style={{ verticalAlign: 'bottom', position: 'relative', height: '21.5%', width: '100%', paddingTop: '20%' }}
							alt="speakx icon"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReadAlongMobilePreview;
