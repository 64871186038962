import React, { useEffect, useState } from 'react';
import { isNotEmptyArray, ObjectMaybe, stringifyQueryParams } from '@bit/yellow_class.utils.common-helpers';
import { Card, CardBody, Col, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from 'reusableComponents/Pagination/Pagination';
import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import { actionSpreader, parseQueryParams, showToast } from 'utils/commonHelpers';
import Breadcrumbs from 'components/Breadcrumbs';
import { setBreadcrumbs } from 'containers/App/globalSlice';
import RankModifier from 'reusableComponents/rankModifier';
import { SelectField } from 'reusableComponents/Form/Select';
import history from '../../App/history';
import { fetchSubtopics, updateSubtopic, updateSubtopicRank } from '../actions/exercises.actions';
import { setApiParams, setPage } from '../ExercisesSlice';
import { getAllCourses, statusList } from '../utils/helpers';
import { ContentDocumentStatusEnum } from '../utils/types';

const heads = [
	{
		accessor: 'displayName',
		Header: 'Title',
	},
	{
		accessor: 'adminCode',
		Header: 'adminCode',
	},
	{
		accessor: 'rank',
		Header: 'Rank',
	},
	{
		accessor: 'status',
		Header: 'Status',
	},
	{
		accessor: 'actions',
		Header: 'Actions',
	},
];

const Actions = ({ params }) => {
	const { subtopicId, status, displayName, adminCode, rank } = params;
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const dispatch = useDispatch();
	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const handleChangeSubtopicStatus = () => {
		const newStatus = status === ContentDocumentStatusEnum.ACTIVE ? ContentDocumentStatusEnum.INACTIVE : ContentDocumentStatusEnum.ACTIVE;
		if (rank === 0 && newStatus === ContentDocumentStatusEnum.ACTIVE) {
			return showToast('error', 'Please set rank number first');
		}
		dispatch(updateSubtopic({ userId: params.userId, subtopicId, updatedSubtopic: { status: newStatus, displayName, adminCode } }));
	};
	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem onClick={() => history.push(`/exercise/list${stringifyQueryParams({ subtopicId })}`)} disabled={false}>
					Show Exercises
				</DropdownItem>
				{adminCode.includes('eg_xaix') && (
					<DropdownItem onClick={() => history.push(`/exercise/questions/list${stringifyQueryParams({ subtopicId })}`)} disabled={false}>
						Show Questions
					</DropdownItem>
				)}
				<DropdownItem
					onClick={() => window.open(`/exercise/add-edit${stringifyQueryParams({ subtopicId, mode: 'add' })}`, '_blank')}
					disabled={false}
				>
					Add Exercises
				</DropdownItem>
				{/* Edit subtopics dropdown button */}
				<DropdownItem
					onClick={() => window.open(`/exercise/subtopic/add-edit${stringifyQueryParams({ subtopicId, mode: 'edit' })}`, '_blank')}
					disabled={false}
				>
					Edit Subtopic
				</DropdownItem>
				<DropdownItem onClick={handleChangeSubtopicStatus} disabled={false}>
					{status === ContentDocumentStatusEnum.ACTIVE
						? `set ${ContentDocumentStatusEnum.INACTIVE}`
						: `set ${ContentDocumentStatusEnum.ACTIVE}`}
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

const createNewRows = ({ subtopics, handleSubtopicRankChange, userId }) => {
	return subtopics.map((curr) => {
		const { id, displayName, adminCode, rank, status } = curr;
		const params = { subtopicId: id, status, userId, adminCode, displayName, rank: rank ?? 0 };
		return {
			id,
			displayName,
			adminCode,
			status,
			actions: <Actions params={params} />,
			rank: <RankModifier onSubmit={handleSubtopicRankChange} uniqueId={id} rank={rank} additionalParams={{}} />,
		};
	});
};

const SubtopicList = (props) => {
	const dispatch = useDispatch();

	const breadcrumbsData = useSelector(({ global }) => ({
		breadcrumbs: global.breadcrumbs,
	}));
	const user = useSelector(({ auth }) => auth.user);

	const subtopicsState = useSelector(({ exercises, auth }) => ({
		subtopics: exercises.subtopics,
		additionalData: exercises.additionalData,
		isLoading: exercises.isLoading,
		isSubmitting: exercises.isSubmitting,
		apiParams: exercises.apiParams,
		total: exercises.total,
		page: exercises.page,
		userId: auth?.user?.id,
	}));

	const [subtopicsRow, setSubtopicsRow] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);
	const fetchSubtopicsHelper = ({ courseId }: { courseId?: string }) => {
		const qp = ObjectMaybe(parseQueryParams(props.location.search));

		const query = {
			...(courseId === '' || courseId ? (courseId === '' ? {} : { 'courses.id': courseId }) : qp.courseId && { 'courses.id': qp.courseId }),
			...(subtopicsState.apiParams?.query && { ...JSON.parse(subtopicsState.apiParams.query) }),
		};

		dispatch(
			fetchSubtopics({
				...subtopicsState.apiParams,
				query: JSON.stringify(query),
				moduleEnum: qp.moduleEnum,
			})
		);
	};

	React.useEffect(() => fetchSubtopicsHelper({}), [subtopicsState.apiParams]);

	React.useEffect(() => {
		setPageOfItems(subtopicsState.page);
	}, [subtopicsState.page]);
	const handleSubtopicRankChange = (subtopicId, rank, additionalParams) => {
		dispatch(
			updateSubtopicRank({ moduleAdminCode: subtopicsState.additionalData?.module.adminCode, subtopicId, rank, updatedBy: user.id })
		);
	};

	const [subtopicCourses, setSubtopicCourses] = useState([]);

	// subtopics to be sorted in ascending order of rank on page load
	React.useEffect(() => {
		const { apiParams } = subtopicsState;

		dispatch(setApiParams({ ...apiParams, sortKey: 'rank', sortOrder: '1' }));
	}, []);

	React.useEffect(() => {
		dispatch(
			actionSpreader(setBreadcrumbs.type, {
				breadcrumbs: [
					{
						text: 'Curriculum',
						url: '/exercise/curriculum/list',
					},
					{
						text: 'Module',
						url: subtopicsState.subtopics[0]?.curriculum?.id
							? `/exercise/module/list${stringifyQueryParams({ curriculumId: subtopicsState.additionalData?.curriculum?.id })}`
							: null,
					},
					{
						text: 'Subtopic',
						url: `/exercise/subtopic/list${stringifyQueryParams({ moduleEnum: subtopicsState.additionalData?.module?.moduleEnum })}`,
					},
				],
			})
		);
		if (isNotEmptyArray(subtopicsState.subtopics)) {
			const structuredRows = createNewRows({
				subtopics: subtopicsState.subtopics,
				handleSubtopicRankChange,
				userId: subtopicsState.userId,
			});
			setSubtopicsRow(structuredRows);
			/** storing the unique courses for eg_xaix curriculum to show search box */
		} else {
			setSubtopicsRow([]);
		}
	}, [subtopicsState.subtopics]);
	useEffect(() => {
		if (subtopicsState.additionalData?.curriculum?.adminCode === 'eg_xaix') {
			const fetchAllCourses = async () => {
				const allCourses = await getAllCourses({});
				if (allCourses?.data) {
					setSubtopicCourses(allCourses.data.map((course) => ({ label: course.name, value: course.id })));
				}
			};
			fetchAllCourses();
		}
	}, [subtopicsState.additionalData]);

	const onChangePage = (itemsPage: number) => {
		const { apiParams } = subtopicsState;
		const skip = apiParams.limit * (itemsPage - 1);
		if (itemsPage) {
			dispatch(setPage(itemsPage));
			dispatch(
				setApiParams({
					...apiParams,
					skip,
				})
			);
		}
	};
	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			const sortOrder = sortDirection === 'ASC' ? '1' : '-1';

			dispatch(setApiParams({ ...subtopicsState.apiParams, sortKey: sortColumn, sortOrder }));
		}
	};

	const onFilterChange = (fieldName, val) => {
		dispatch(
			setApiParams({
				...subtopicsState.apiParams,
				query: val && JSON.stringify({ [fieldName]: val }),
			})
		);
	};

	const onCourseFilter = (fieldName, val) => {
		const qp = ObjectMaybe(parseQueryParams(props.location.search));

		history.push(`/exercise/subtopic/list${stringifyQueryParams({ moduleEnum: qp.moduleEnum, ...(val && { [fieldName]: val }) })}`);
		fetchSubtopicsHelper({ courseId: val });
	};

	return (
		<Card>
			<CardBody>
				<Row className="mt-1 mb-4 rounded">
					<Col sm={6}>
						<Breadcrumbs breadcrumbs={breadcrumbsData.breadcrumbs} />
					</Col>
					<Col sm={3}>
						<SelectField
							clearable
							placeholder="Status"
							name="status"
							options={statusList}
							onChange={(val) => onFilterChange('status', val)}
						/>
					</Col>
					<Col sm={3}>
						<Button
							onClick={() =>
								window.open(
									`/exercise/subtopic/add-edit${stringifyQueryParams({
										moduleEnum: subtopicsState.additionalData?.module?.moduleEnum,
										mode: 'add',
									})}`,
									'_blank'
								)
							}
						>
							{' '}
							Add Subtopic{' '}
						</Button>
					</Col>
				</Row>

				{subtopicCourses.length > 0 && (
					<Row className="mt-1 mb-4 rounded">
						<Col sm={8}>
							<SelectField
								clearable
								placeholder="Course"
								name="course"
								options={subtopicCourses}
								onChange={(val) => onCourseFilter('courseId', val)}
								value={
									ObjectMaybe(parseQueryParams(props.location.search))?.courseId &&
									subtopicCourses.find((course) => course.value === ObjectMaybe(parseQueryParams(props.location.search))?.courseId)
								}
							/>
						</Col>
					</Row>
				)}
				<Row className="mt-1 mb-4 rounded">
					<Col sm={6}>
						{subtopicsState.additionalData?.curriculum?.displayName && (
							<h4>Curriculum Name : {subtopicsState.additionalData?.curriculum?.displayName}</h4>
						)}
						{subtopicsState.additionalData?.curriculum?.adminCode && (
							<h4>Curriculum AdminCode : {subtopicsState.additionalData?.curriculum?.adminCode}</h4>
						)}
					</Col>
					<Col sm={6}>
						{subtopicsState.additionalData?.module?.displayName && (
							<h4>Module Name : {subtopicsState.additionalData?.module?.displayName}</h4>
						)}
						{subtopicsState.additionalData?.module?.adminCode && (
							<h4>Module AdminCode : {subtopicsState.additionalData?.module?.adminCode}</h4>
						)}
					</Col>
				</Row>

				{isNotEmptyArray(subtopicsRow) && (
					<>
						<DataPaginationTable heads={heads} rows={subtopicsRow} onSort={onSort} />
						<Pagination
							itemsCount={subtopicsState.total}
							itemsToShow={subtopicsState.apiParams.limit}
							pageOfItems={pageOfItems}
							onChangePage={onChangePage}
						/>
					</>
				)}
				{subtopicsState.total === 0 && <div className="">No subtopics</div>}
			</CardBody>
		</Card>
	);
};

export default SubtopicList;
