import React, { useRef, useState } from 'react';
import { isNotEmptyArray, ObjectMaybe, stringifyQueryParams } from '@bit/yellow_class.utils.common-helpers';
import { Card, CardBody, Col, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from 'reusableComponents/Pagination/Pagination';
import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import Breadcrumbs from 'components/Breadcrumbs';
import { actionSpreader, parseQueryParams } from 'utils/commonHelpers';
import { setBreadcrumbs } from 'containers/App/globalSlice';
import { useHistory } from 'react-router';
import { SelectField } from 'reusableComponents/Form/Select';
import {
	deleteExerciseQuestion,
	fetchExerciseQuestions,
	fetchSimilarQuestions,
	fetchSubtopic,
	reorderExerciseQuestions,
	setExerciseQuestionStatus,
	toggleExerciseQuestionStatus,
} from '../actions/exercises.actions';
import { setApiParams, setPage } from '../ExercisesSlice';
import { ContentDocumentStatusEnum, DocumentStatusEnum, QuestionTypesEnum, TExerciseQuestion } from '../utils/types';
import AddExistingQuestionPopup from './AddExistingQuestionPopup';
import QuestionPreview from './QuestionPreview';
import { questionDisplayFormatter } from '../utils/questionListHelpers';

import { QuestionTypeFields } from '../utils/helpers';

const heads = [
	{
		accessor: 'title',
		Header: 'Title',
	},
	{
		accessor: 'roleName',
		Header: 'Role Name',
	},
	{
		accessor: 'adminCode',
		Header: 'adminCode',
	},
	{
		accessor: 'type',
		Header: 'Question Type',
	},
	{
		accessor: 'status',
		Header: 'Global Status',
	},
	{
		accessor: 'questionStatus',
		Header: 'Exercise Level Status',
	},
	{
		accessor: 'difficulty',
		Header: 'DL',
	},
	{
		accessor: 'actions',
		Header: 'Actions',
	},
];

const Actions = ({
	id: questionId,
	idx,
	totalQuestions,
	isSimilarQuestion,
	status,
	exerciseId,
	onPreviewQuestion,
	onMoveUpQuestion,
	onMoveDownQuestion,
	showPlayQuestion,
	adminCode,
}: Pick<TExerciseQuestion, 'id' | 'status'> & {
	exerciseId: string;
	idx: number;
	isSimilarQuestion: boolean;
	totalQuestions: number;
	onPreviewQuestion: () => void;
	onMoveUpQuestion: () => void;
	onMoveDownQuestion: () => void;
	showPlayQuestion: boolean;
	adminCode: string;
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prevState) => !prevState);

	const dispatch = useDispatch();

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle} onClick={(e) => e.stopPropagation()}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				{isSimilarQuestion ? (
					<DropdownItem
						onClick={() => window.open(`/exercise/questions/add-edit${stringifyQueryParams({ questionId, mode: 'edit' })}`, '_blank')}
						disabled={false}
					>
						Edit question
					</DropdownItem>
				) : (
					<>
						<DropdownItem
							onClick={() =>
								window.open(`/exercise/questions/add-edit${stringifyQueryParams({ questionId, exerciseId, mode: 'edit' })}`, '_blank')
							}
							disabled={false}
						>
							Edit question
						</DropdownItem>

						{!adminCode.includes('eg_xaix') && (
							<DropdownItem
								onClick={() =>
									window.open(`/exercise/questions/list${stringifyQueryParams({ exerciseId, questionId, mode: 'similar' })}`, '_blank')
								}
								disabled={false}
							>
								Show similar questions
							</DropdownItem>
						)}
						{showPlayQuestion && (
							<DropdownItem
								onClick={() => window.open(`/exercise/questions/list${stringifyQueryParams({ siblingId: questionId })}`, '_blank')}
								disabled={false}
							>
								Show Questions
							</DropdownItem>
						)}

						{exerciseId && (
							<DropdownItem
								onClick={() => {
									dispatch(toggleExerciseQuestionStatus({ questionId, exerciseId }));
								}}
								disabled={false}
							>
								Toggle question status in this exercise
							</DropdownItem>
						)}
						{exerciseId && (
							<DropdownItem
								onClick={() => {
									// eslint-disable-next-line no-restricted-globals, no-alert
									const shouldRemoveQuestion = confirm('Are you sure you want to delete this question?');
									if (shouldRemoveQuestion) {
										dispatch(deleteExerciseQuestion({ questionId, exerciseId }));
									}
								}}
								disabled={false}
							>
								Remove question from exercise
							</DropdownItem>
						)}
						{!exerciseId && [ContentDocumentStatusEnum.INACTIVE.toString(), ContentDocumentStatusEnum.DRAFT.toString()].includes(status) && (
							<DropdownItem
								onClick={() => {
									dispatch(setExerciseQuestionStatus({ questionId, status: ContentDocumentStatusEnum.ACTIVE }));
								}}
								disabled={false}
							>
								Set ACTIVE
							</DropdownItem>
						)}
						{exerciseId && status === ContentDocumentStatusEnum.DRAFT.toString() && (
							<DropdownItem
								onClick={() => {
									dispatch(setExerciseQuestionStatus({ questionId, status: ContentDocumentStatusEnum.ACTIVE }));
								}}
								disabled={false}
							>
								Set ACTIVE
							</DropdownItem>
						)}
						{!exerciseId && status === ContentDocumentStatusEnum.ACTIVE.toString() && (
							<DropdownItem
								onClick={() => {
									dispatch(setExerciseQuestionStatus({ questionId, status: ContentDocumentStatusEnum.INACTIVE }));
								}}
								disabled={false}
							>
								Set INACTIVE
							</DropdownItem>
						)}
						<DropdownItem onClick={onPreviewQuestion} disabled={false}>
							Show Preview
						</DropdownItem>
						{idx !== 0 && (
							<DropdownItem onClick={onMoveUpQuestion} disabled={false}>
								Move Up
							</DropdownItem>
						)}
						{idx !== totalQuestions - 1 && (
							<DropdownItem onClick={onMoveDownQuestion} disabled={false}>
								Move Down
							</DropdownItem>
						)}
					</>
				)}
			</DropdownMenu>
		</Dropdown>
	);
};

const createNewRows = ({
	questions,
	exerciseId,
	isSimilarQuestion,
	onPreviewQuestion,
	onMoveUpQuestion,
	onMoveDownQuestion,
	showPlayQuestion,
}: {
	questions: Array<TExerciseQuestion & { questionStatus?: DocumentStatusEnum }>;
	exerciseId: string;
	isSimilarQuestion: boolean;
	onPreviewQuestion;
	onMoveUpQuestion;
	onMoveDownQuestion;
	showPlayQuestion: boolean;
}) => {
	return questions.map((questionDetail, idx) => {
		const { id, adminCode, type, status, questionStatus, difficulty } = questionDetail;

		let roleName;
		if (questionDetail.type === QuestionTypesEnum.CONTENT_ONLY) {
			roleName = questionDetail.roleName;
		}

		const title = questionDisplayFormatter(questionDetail);

		return {
			id,
			title,
			roleName,
			adminCode,
			type,
			status,
			questionStatus,
			difficulty: Math.round(difficulty),
			actions: (
				<Actions
					id={id}
					idx={idx}
					isSimilarQuestion={isSimilarQuestion}
					totalQuestions={questions.length}
					status={status}
					exerciseId={exerciseId}
					onPreviewQuestion={() => onPreviewQuestion(id)}
					onMoveUpQuestion={() => onMoveUpQuestion(id, idx)}
					onMoveDownQuestion={() => onMoveDownQuestion(id, idx)}
					showPlayQuestion={showPlayQuestion}
					adminCode={adminCode}
				/>
			),
			onClick: () => {
				onPreviewQuestion(id);
			},
		};
	});
};

const ExerciseQuestionList = (props) => {
	const dispatch = useDispatch();

	const breadcrumbsData = useSelector(({ global }) => ({
		breadcrumbs: global.breadcrumbs,
	}));

	const exercisesState = useSelector(({ exercises }) => ({
		questions: exercises.questions,
		additionalData: exercises.additionalData,
		isLoading: exercises.isLoading,
		isSubmitting: exercises.isSubmitting,
		apiParams: exercises.apiParams,
		total: exercises.total,
		page: exercises.page,
		subtopics: exercises.subtopics,
	}));

	const history = useHistory();

	history.listen((e) => window.location.reload()); // as this component is reusing at 2 diff places, so we need to reload the page on navigation

	const [exercisesRow, setExercisesRow] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);
	const [showPlayQuestion, setShowPlayQuestion] = useState(false);

	React.useEffect(() => {
		const qp = ObjectMaybe(parseQueryParams(props.location.search));

		if (qp.subtopicId) {
			dispatch(fetchSubtopic({ subtopicId: qp.subtopicId }));
		}

		if (qp.mode === 'similar') {
			dispatch(fetchSimilarQuestions({ ...exercisesState.apiParams, parentId: qp.questionId }));
		} else {
			dispatch(
				fetchExerciseQuestions({
					...exercisesState.apiParams,
					...(qp.siblingId && { sortOrder: '1', sortKey: 'rank' }),
					exerciseId: qp.exerciseId,
					subtopicId: qp.subtopicId,
					siblingId: qp.siblingId,
				})
			);
		}
	}, [exercisesState.apiParams]);

	React.useEffect(() => {
		setPageOfItems(exercisesState.page);
	}, [exercisesState.page]);

	React.useEffect(() => {
		const qp = ObjectMaybe(parseQueryParams(props.location.search));
		dispatch(
			actionSpreader(setBreadcrumbs.type, {
				breadcrumbs: [
					{
						text: 'Curriculum',
						url: '/exercise/curriculum/list',
					},
					{
						text: 'Module',
						url: `/exercise/module/list${stringifyQueryParams({ curriculumId: exercisesState.additionalData?.curriculum?.id })}`,
					},
					{
						text: 'Subtopic',
						url: `/exercise/subtopic/list${stringifyQueryParams({ moduleEnum: exercisesState.additionalData?.module?.moduleEnum })}`,
					},
					{
						text: 'Exercise',
						url: `/exercise/list${stringifyQueryParams({ subtopicId: exercisesState.additionalData?.subtopic?.id })}`,
					},
					{
						text: 'Questions',
						url: `/exercise/questions/list${stringifyQueryParams({ exerciseId: qp.exerciseId })}`,
					},
				],
			})
		);

		if (isNotEmptyArray(exercisesState.questions)) {
			const structuredRows = createNewRows({
				questions: exercisesState.questions,
				isSimilarQuestion: qp.mode === 'similar',
				exerciseId: qp.exerciseId,
				onPreviewQuestion: (questionId) => {
					questionPreviewRef.current?.show?.(questionId);
				},
				onMoveUpQuestion: (questionId, idx) => {
					dispatch(reorderExerciseQuestions({ currentIdx: idx, move: 'UP', exerciseId: qp.exerciseId }));
				},
				onMoveDownQuestion: (questionId, idx) => {
					dispatch(reorderExerciseQuestions({ currentIdx: idx, move: 'DOWN', exerciseId: qp.exerciseId }));
				},
				showPlayQuestion,
			});

			setExercisesRow(structuredRows);
		} else {
			setExercisesRow([]);
		}
	}, [exercisesState.questions]);

	const onChangePage = (itemsPage: number) => {
		const { apiParams } = exercisesState;
		const skip = apiParams.limit * (itemsPage - 1);
		if (itemsPage) {
			dispatch(setPage(itemsPage));
			dispatch(
				setApiParams({
					...apiParams,
					skip,
				})
			);
		}
	};

	const addExistingQuestionPopupRef = useRef<any>();
	const questionPreviewRef = useRef<any>();
	const qp = ObjectMaybe(parseQueryParams(props.location.search));
	const onFilterChange = (key, value) => {
		const qp = ObjectMaybe(parseQueryParams(props.location.search));
		if (qp.mode === 'similar') {
			dispatch(fetchSimilarQuestions({ ...exercisesState.apiParams, parentId: qp.questionId, [key]: value }));
		} else {
			dispatch(
				fetchExerciseQuestions({
					...exercisesState.apiParams,

					...(showPlayQuestion ? { query: JSON.stringify({ showPlayQuestion: true }) } : { exerciseId: qp.exerciseId }),
					subtopicId: qp.subtopicId,
					[key]: value,
				})
			);
		}
	};
	const onShowFilterChange = (key, value) => {
		setShowPlayQuestion(value);
		const qp = ObjectMaybe(parseQueryParams(props.location.search));
		dispatch(
			fetchExerciseQuestions({
				...exercisesState.apiParams,
				...(value ? { query: JSON.stringify({ showPlayQuestion: true }) } : { exerciseId: qp.exerciseId }),
				subtopicId: qp.subtopicId,
			})
		);
	};
	return (
		<Card>
			<CardBody>
				<Row className="mt-1 mb-4 rounded">
					<Col sm={12}>
						<Breadcrumbs breadcrumbs={breadcrumbsData.breadcrumbs} />
					</Col>
				</Row>
				<Row className="mt-1 mb-4 rounded">
					<Col sm={6}>
						<h3>Exercise Questions Listing</h3>
					</Col>
					{!qp.siblingId && exercisesState.questions[0]?.adminCode?.includes('eg_xaix') && (
						<Col sm={4}>
							<SelectField
								clearable
								placeholder="Show Play Question"
								name="showPlayQuestion"
								options={[
									{ label: 'Yes', value: true },
									{ label: 'No', value: false },
								]}
								onChange={(val) => onShowFilterChange('showPlayQuestion', val)}
							/>
						</Col>
					)}
				</Row>
				<Row className="mt-1 mb-4 rounded">
					{exercisesState?.subtopics[0] && (
						<Col sm={6}>
							<h4>Subtopic Name - {exercisesState.subtopics[0]?.displayName}</h4>
							<h4>Admin Code - {exercisesState.subtopics[0]?.adminCode}</h4>
						</Col>
					)}
					{/* {!qp.siblingId && (
						<Col sm={6}>
							<h4>
								Similar Questions, Parent question Id -{' '}
								<a
									href={`/exercise/questions/add-edit${stringifyQueryParams({ questionId: qp.questionId, mode: 'edit' })}`}
									target="blank"
									// onClick={() =>
									// 	window.open(`/exercise/questions/add-edit${stringifyQueryParams({ questionId: qp.questionId, mode: 'edit' })}`, '_blank')
									// }
								>
									{qp.questionId}
								</a>
							</h4>
						</Col>
					)} */}
					{!showPlayQuestion && !qp.siblingId && (
						<Col sm={4}>
							<SelectField
								clearable
								placeholder="Question Type"
								name="questionType"
								options={QuestionTypeFields}
								onChange={(val) => onFilterChange('questionType', val)}
							/>
						</Col>
					)}
				</Row>
				{qp.exerciseId && (
					<Row>
						<Col>
							<Button onClick={() => addExistingQuestionPopupRef.current?.show?.(qp.exerciseId)}>Add an existing Question</Button>
							<Button
								onClick={() =>
									window.open(`/exercise/questions/add-edit${stringifyQueryParams({ exerciseId: qp.exerciseId, mode: 'add' })}`, '_blank')
								}
							>
								Add a new Question
							</Button>
						</Col>
						<AddExistingQuestionPopup ref={addExistingQuestionPopupRef} />
					</Row>
				)}
				{isNotEmptyArray(exercisesRow) && (
					<>
						<DataPaginationTable heads={heads} rows={exercisesRow} />
						<Pagination
							itemsCount={exercisesState.total}
							itemsToShow={exercisesState.apiParams.limit}
							pageOfItems={pageOfItems}
							onChangePage={onChangePage}
						/>
					</>
				)}
				{exercisesState.total === 0 && <div className="">No data</div>}
			</CardBody>
			<QuestionPreview ref={questionPreviewRef} />
		</Card>
	);
};

export default ExerciseQuestionList;
